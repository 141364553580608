/* eslint-disable */
import Api from "./Api";
export default {
    async getCurrentAuth() {
        const response = await Api().post(`api/dashboard/masters/role/getAuth`, {});
        console.log("response=>",response?.data?.data?.user_id);
        if(response?.data?.data?.user_id){
            // alert("PS")
            
        }else{
            // alert("PSS")
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.clear();
            window.location.href="/";
        }
        return response;
    },
}

