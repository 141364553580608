import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import UsersList from "../container/pages/users/Users";
import RoleList from "../container/pages/role/RoleList";
import CountryList from "../container/pages/country/CountryList";
import Profile from "../container/pages/profile/Profile";
import GenderList from "../container/pages/gender/Gender";
import StateList from "../container/pages/state/State";
import CityList from "../container/pages/city/CityNew";
import EditPermissions from "../container/pages/role/EditPermissions";
import WhopUser from "../container/pages/whop/users/Users";
import WhopProduct from "../container/pages/whop/product/Product";
import WhopLicense from "../container/pages/whop/license/License";
import WhopAddLicense from "../container/pages/whop/license/addLicense";
import WhopAccount from "../container/pages/whop/account/Account";
import WhopAddAccount from "../container/pages/whop/account/addAccount";
import PlanList from "../container/pages/whop/plan/PlanList";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import AccMetrix from "../container/pages/account_metrix/AccMetrix";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<UsersList />} />
          <Route path="/plan" element={<PlanList />} />
          <Route path="/customer" element={<WhopUser />} />
          <Route path="/product" element={<WhopProduct />} />
          <Route path="/license" element={<WhopLicense />} />
          <Route path="/license">
            <Route index element={<WhopLicense />} />
            <Route path=":wp_lic_id" element={<WhopAddLicense />} />
          </Route>
          <Route path="/account" element={<WhopAccount />} />
          <Route path="/account">
            <Route index element={<WhopAccount />} />
            <Route path=":fk_wp_lic_id" element={<WhopAddAccount />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-list" element={<UsersList />} />

          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/country" element={<CountryList />} />
          <Route path="/state" element={<StateList />} />
          <Route path="/city" element={<CityList />} />
          <Route path="/accm" element={<AccMetrix />} />
          {/* <Route path="/subscription/">
            <Route index element={<Subscription />} />
            <Route path=":customer_id" element={<Subscription />} />
          </Route> */}
        </Route>
      </Routes>
    </Router>
  );

};

export default RoutesConfig;
