import { Link } from "react-router-dom";
import { capitalise } from "../../utilities/utilities";
import { Switch } from "@headlessui/react";
import "./style.css"; // import CSS file
import moment from "moment";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CurrencyRupeeIcon, PlusIcon } from "@heroicons/react/20/solid";
import { PencilIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { BsCurrencyRupee } from "react-icons/bs";

import {
  calculateAge,
} from "../../helper/commonHelper";

import { FaDownload, FaSpinner } from 'react-icons/fa';

function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function magazineStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineIssueStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function productStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const user_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.user_first_name) + " " + capitalise(d.user_last_name),
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Mobile",
      accessor: "user_mobile",
    },
    {
      Header: "Role",
      accessor: (d) => capitalise(d.m_role_relation?.role_name),
    },
    {
      Header: "Status",
      accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const enabled = cell.row.original.user_is_active;
        const isBtn = cell.row.original.isBtn;

        if (
          allowedActions?.filter((data) => data.permission_id === 7)?.length >
          0 &&
          isBtn
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 6);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 8);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }

        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 6)?.length > 0 && isBtn) || localStorage.getItem("user_id") == cellId ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {(allowedActions.filter((data) => data.permission_id == 8)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];

export const role_columns = ({
  onDeleteOpen,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: (d) => capitalise(d.role_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.role_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {/* {isBtn && ( */}
            {(allowedActions.filter((data) => data.permission_id == 10)?.length >
              0 && isBtn) || (localStorage.getItem("role_id") == 1) ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 11)?.length >
              0 && isBtn ? (
              // {isBtn && (
              <Link
                to={`/role/${cellId}`}
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit Permissions
                </button>
              </Link>
            ) : null}

            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 12)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];

export const country_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Country",
    accessor: (d) => capitalise(d.country_name),
  },
  {
    Header: "Country Code",
    accessor: "country_code",
  },

  {
    Header: "Country ISD Code",
    accessor: "country_isd_code",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.country_id;
      const country_is_edit = cell.row.original.country_is_edit;
      return (
        <>
          {country_is_edit && (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>
          )}

          {country_is_edit && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];

export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Gender English",
    accessor: (d) => capitalise(d.gender_name),
  },
  {
    Header: "Gender Arabic",
    accessor: (d) => capitalise(d.gender_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const state_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "State Name English",
    accessor: (d) => capitalise(d.state_name),
  },
  {
    Header: "State Name Arabic",
    accessor: (d) => capitalise(d.state_name_ar),
  },
  {
    Header: "Country Name",
    accessor: (d) => capitalise(d.m_country_relation.country_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.state_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const city_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "City Name English",
    accessor: (d) => capitalise(d.city_name),
  },
  {
    Header: "City Name Arabic",
    accessor: (d) => capitalise(d.city_name_ar),
  },
  {
    Header: "State En",
    accessor: (d) => capitalise(d.m_state_relation.state_name),
  },
  {
    Header: "State Ar",
    accessor: (d) => capitalise(d.m_state_relation.state_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.city_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const customer_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  handleBanUserAcc
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    /* {
      Header: "User ID",
      accessor: "user_id",
    }, */
    {
      Header: "Customer Name",
      accessor: (d) =>
        capitalise(d.user_name),
    },
    {
      Header: "Customer User Name",
      accessor: "user_username",
    },
    {
      Header: "Customer Email",
      accessor: "user_email",
    },
    {
      Header: "Total License",
      accessor: "license",
    },
    {
      Header: "Total Account",
      accessor: "accounts",
    },
    /* {
      Header: "Customer Pic",
      accessor: "user_profile_pic_url",

      Cell: (cell) => {
        const user_profile_pic_url = cell.row.original.user_profile_pic_url;
        const user_name = cell.row.original.user_name;
        return (
          user_profile_pic_url ? (<img src={user_profile_pic_url} width="40" height="40" alt={user_name} />) : (<></>));
      }
    }, */
    /* {
      Header: "Status",
      accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.wp_user_id;
        const enabled = cell.row.original.user_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 24)?.length >
          0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    }, */
    {
      Header: "BAN (is Banned)",
      accessor: (d) => (d.user_is_banned == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const user_id = cell.row.original.wp_user_id;
        const enabled = cell.row.original.user_is_banned;

        if (
          allowedActions?.filter((data) => data.permission_id === 24)?.length >
          0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => handleBanUserAcc(user_id, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    /* {
      Header: "Created By",
      accessor: (d) => capitalise(d.wp_user_cu_relation?.user_first_name),
    },
    {
      Header: "Updated By",
      accessor: (d) => capitalise(d.wp_user_uu_relation?.user_first_name),
    }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.wp_user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 23);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 25);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }

        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 23)?.length > 0) ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {(allowedActions.filter((data) => data.permission_id == 25)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];


export const product_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    /* {
      Header: "Product ID",
      accessor: "prod_id",
    }, */
    {
      Header: "Product Name",
      accessor: (d) =>
        capitalise(d.prod_name),
    },
    {
      Header: "Product Desc",
      accessor: "prod_description",
    },
    /* {
      Header: "Company Id",
      accessor: "prod_company_id",
    },
    {
      Header: "Page Id",
      accessor: "prod_page_id",
    }, */
    {
      Header: "Visibility",
      accessor: "prod_visibility",
    },

    {
      Header: "Status",
      accessor: (d) => (d.prod_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.wp_prod_id;
        const enabled = cell.row.original.prod_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 30)?.length >
          0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    /* {
      Header: "Created By",
      accessor: (d) => capitalise(d.prod_cu_relation?.user_first_name),
    },
    {
      Header: "Updated By",
      accessor: (d) => capitalise(d.prod_uu_relation?.user_first_name),
    }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.wp_prod_id;
        const isBtn = cell.row.original.isBtn;
        const prod_is_deleted = cell.row.original.prod_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 29);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 31);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }
        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 29)?.length > 0) ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {(allowedActions.filter((data) => data.permission_id == 25)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, prod_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];

export const license_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Product Name",
      accessor: (d) => capitalise(d.master_whop_product_relation?.prod_name),
    },
    {
      Header: "User Name",
      accessor: (d) => capitalise(d.master_whop_user_relation?.user_name),
    },
    {
      Header: "License ID",
      accessor: "lic_mem_id",
    },
    /* {
      Header: "Affiliate Name",
      accessor: (d) => capitalise(d.lic_affiliate_username),
    },
    {
      Header: "Checkout ID",
      accessor: "lic_checkout_id",
    },
    {
      Header: "Company Buyer Id",
      accessor: "lic_company_buyer_id",
    }, */
    {
      Header: "License Key",
      accessor: "license_key",
    },
    /* {
      Header: "Page ID",
      accessor: "lic_page_id",
    }, */ {
      Header: "Plan ID",
      accessor: "lic_plan_id",
    }, {
      Header: "Product ID",
      accessor: "lic_product_id",
    }, {
      Header: "User ID",
      accessor: "lic_user_id",
    }, {
      Header: "License Status",
      accessor: "lic_status",
    }, {
      Header: "Quantity",
      accessor: "lic_quantity",
    }, /* {
      Header: "Cancel At Period End",
      accessor: "lic_cancel_at_period_end",
    }, {
      Header: "Market Place",
      accessor: "lic_marketplace",
    }, {
      Header: "Valid",
      accessor: "lic_valid",
    }, */ {
      Header: "Renewal Period Start",
      accessor: "lic_renewal_period_start",
    }, {
      Header: "Renewal Period End",
      accessor: "lic_renewal_period_end",
    },
    {
      Header: "Created AT",
      accessor: "lic_created_at",
    }, {
      Header: "Expire AT",
      accessor: "lic_expires_at",
    },
    {
      Header: "Status",
      accessor: (d) => (d.lic_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.wp_lic_id;
        const enabled = cell.row.original.lic_is_active;

        if (
          allowedActions?.filter((data) => data.permission_id === 36)?.length >
          0
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    /* {
      Header: "Created By",
      accessor: (d) => capitalise(d.lic_cu_relation?.user_first_name),
    },
    {
      Header: "Updated By",
      accessor: (d) => capitalise(d.lic_uu_relation?.user_first_name),
    }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.wp_lic_id;
        const isBtn = cell.row.original.isBtn;
        const lic_is_deleted = cell.row.original.lic_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 35);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 37);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }
        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 35)?.length > 0) ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              <Link
                to={`../account/${cellId}`}
              >
                <button
                  className="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  size="default"
                  variant="outlined"
                >
                  Account<span className="sr-only">, </span>
                </button>
              </Link>
              {(allowedActions.filter((data) => data.permission_id == 37)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, lic_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];
export const account_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
  goToMetrix,
  addParamConfig,
  setConfigData
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Product Name",
      accessor: (d) => capitalise(d.master_whop_license_relation?.master_whop_product_relation?.prod_name),
    },
    {
      Header: "Account No",
      accessor: "lam_account_no",
    },
    {
      Header: "User Name",
      accessor: (d) => capitalise(d.master_whop_user_relation?.user_name),
    },
    {
      Header: "User Email",
      accessor: (d) => capitalise(d.master_whop_user_relation?.user_email),
    },
    {
      Header: "MetriX",
      accessor: "metrix",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_active;
        const acc_id = cell.row.original.lam_account_no;
        const user_id = cell.row.original.fk_wp_user_id;
        const username = cell.row.original.master_whop_user_relation.user_username;

        return (
          <button type="button" class="rounded bg-slate-100 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 transition ease-in-out duration-150 cursor-pointer leading-6"
            onClick={(e) => {
              //navigate("/accm?uid="+cellId+"&name="+cellId+"&accid="+client.mua_acc_login);
              console.log(cell.row);
              goToMetrix("/accm?uid="+user_id+"&name="+username+"&accid="+acc_id);
            }}
          >
            Go to MetriX
          </button>
        );

      },
    },
    {
      Header: "Parameter",
      accessor: "config",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_active;
        const acc_id = cell.row.original.lam_account_no;
        const user_id = cell.row.original.fk_wp_user_id;
        const username = cell.row.original.master_whop_user_relation.user_username;

        return (
          <button type="button" class="rounded bg-slate-100 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 transition ease-in-out duration-150 cursor-pointer leading-6"
            onClick={(e) => {
              setConfigData('');
              addParamConfig(cell.row.original);
            }}
          >
            Add Config
          </button>
        );

      },
    },
    {
      Header: "STOP TRADE",
      accessor: (d) => (d.lam_is_stopped_trading == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_stopped_trading;

        if (
          allowedActions?.filter((data) => data.permission_id === 54)?.length >
          0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled, 'lam_is_stopped_trading')}
                className={userStatusToggle(
                  enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "CLOSE ALL TRADE",
      accessor: (d) => (d.lam_is_closed_all_trade == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_closed_all_trade;

        if (
          allowedActions?.filter((data) => data.permission_id === 54)?.length >
          0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled, 'lam_is_closed_all_trade')}
                className={userStatusToggle(
                  enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "BAN (IS BANNED)",
      accessor: (d) => (d.lam_is_banned == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const enabled = cell.row.original.lam_is_banned;
        const userBanned = cell.row.original.master_whop_user_relation.user_is_banned;

        if (
          allowedActions?.filter((data) => data.permission_id === 54)?.length >
          0
        ) {
          return (
            <div className="flex flex-col justify-center items-center">
              <Switch
                disabled={userBanned}
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled, 'lam_is_banned')}
                className={userStatusToggle(
                  enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <span className="text-red-500 text-xs mt-2">{userBanned ? "User is Banned" : null}</span>
            </div>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "License Key",
      accessor: (d) => capitalise(d.master_whop_license_relation?.license_key),
    },
    /* {
      Header: "Account ID",
      accessor: "lam_id",
    },{
      Header: "User ID",
      accessor: "fk_wp_user_id",
    },
    {
      Header: "License ID",
      accessor: "fk_wp_lic_id",
    },
    {
      Header: "License Affiliate Username",
      accessor: (d) => capitalise(d.master_whop_license_relation?.lic_affiliate_username),
    },
    {
      Header: "Created By",
      accessor: (d) => capitalise(d.lam_cu_relation?.user_first_name),
    },
    {
      Header: "Updated By",
      accessor: (d) => capitalise(d.lam_uu_relation?.user_first_name),
    }, */
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.lam_id;
        const isBtn = cell.row.original.isBtn;
        const lam_is_deleted = cell.row.original.lam_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 53);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 55);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }

        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 53)?.length > 0) ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {(allowedActions.filter((data) => data.permission_id == 55)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, lam_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];


  export const plan_columns = ({
    onDeleteOpen,
    onDeleteOpenSection,
    handleDrawer,
    allowedActions,
  }) => [
      {
        Header: "SN",
        accessor: (d, i) => (i + 1),
        Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
      },
      {
        Header: "Plan ID",
        accessor: "plan_id",
      },{
        Header: "Product ID",
        accessor: "plan_product",
      },
      {
        Header: "Plan Type",
        accessor: "plan_plan_type",
      },
      {
        Header: "Release Method",
        accessor: "plan_release_method",
      },
      {
        Header: "Visibility",
        accessor: "plan_visibility",
      },{
        Header: "Billing Period",
        accessor: "plan_billing_period",
      },
      {
        Header: "Direct Link",
        accessor: "plan_direct_link",
      },
      {
        Header: "Renewal Price",
        accessor: "plan_renewal_price",
      },
      {
        Header: "Initial Price",
        accessor: "plan_initial_price",
      },
      {
        Header: "Base Currency",
        accessor: "plan_base_currency",
      },
      {
        Header: "Payment Method",
        accessor: "plan_accepted_payment_methods",
      },
      {
        Header: "Product Name",
        accessor: (d) => capitalise(d.master_whop_product_relation?.prod_name),
      },
      {
        Header: "Status",
        accessor: (d) => (d.plan_is_active == true ? "Active" : "Inactive"),
        Cell: (cell) => {
          const cellId = cell.row.original.plan_id;
          const enabled = cell.row.original.plan_is_active;
  
          if (
            allowedActions?.filter((data) => data.permission_id === 62)?.length >
            0
          ) {
            return (
              <Switch
                checked={enabled}
                onChange={() => onDeleteOpen(cellId, enabled)}
                className={userStatusToggle(
                  enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            );
          } else {
            // Add a default case or return null if nothing should be rendered
            return null;
          }
        },
      },
      {
        Header: "Created By",
        accessor: (d) => capitalise(d.plan_cu_relation?.user_first_name),
      },
      {
        Header: "Updated By",
        accessor: (d) => capitalise(d.plan_uu_relation?.user_first_name),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (cell) => {
          const cellId = cell.row.original.plan_id;
          const isBtn = cell.row.original.isBtn;
          const plan_is_deleted = cell.row.original.plan_is_deleted;
          const getEditPermission = allowedActions.filter((data) => data.permission_id == 61);
          const getDeletePermission = allowedActions.filter((data) => data.permission_id == 63);
          const getUid = localStorage.getItem("user_id");
          let actionButtonStatus = false;
          if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
            actionButtonStatus = true;
          }
  
          return (
            <>
  
              <span className="isolate inline-flex rounded-md shadow-sm">
                {(allowedActions.filter((data) => data.permission_id == 61)?.length > 0) ? (
                  <button
                    onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                    type="button"
                    className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                  >
                    <span className="sr-only">Edit</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                ) : null}
  
                {(allowedActions.filter((data) => data.permission_id == 63)?.length > 0 && isBtn) ? (
                  <button
                    onClick={() => onDeleteOpenSection(cellId, plan_is_deleted)}
                    type="button"
                    className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                  >
                    <span className="sr-only">Delete</span>
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                ) : null}
              </span>
  
            </>
          );
        },
      },
    ];

export const matrics_trade_history_columns = ({}) => [
    {
      Header: "Ticket",
      accessor: "mth_order_ticket",
    },
    {
      Header: "Open Time",
      accessor: "mth_order_open_time",
    },
    /* {
      Header: "Type",
      accessor: "mth_order_type",
    }, */
    {
      Header: "Type",
      accessor: "mth_order_type",
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value == 0 ? 'BUY' : 'SELL' }</div>, // Adding a class to the cell
    },
    {
      Header: "Lots",
      accessor: "mth_order_lots",
    },
    {
      Header: "Symbol",
      accessor: "mth_order_symbol",
    },
    {
      Header: "Open Price ($)",
      accessor: "mth_order_open_price",
    },
    {
      Header: "Close Price ($)",
      accessor: "mth_order_close_price",
    },
    {
      Header: "Close Time",
      accessor: "mth_order_close_time",
    },
    {
      Header: "SL",
      accessor: "mth_order_sl",
    },
    {
      Header: "TP",
      accessor: "mth_order_tp",
    },
    {
      Header: "Profit",
      accessor: "mth_order_profit",
    },
    {
      Header: "Comission",
      accessor: "mth_order_comm",
    },
    {
      Header: "Swap",
      accessor: "mth_order_swap",
    },
    {
      Header: "Net PNL",
      accessor: "mth_scp_sum",
    }
    /* {
      Header: "Type",
      accessor: (d) => capitalise(d.m_role_relation?.role_name),
    } */
  ];

export const matrics_trade_live_columns = ({}) => [
    {
      Header: "Order Ticket",
      accessor: "lt_order_ticket",
    },
    {
      Header: "Order Time",
      accessor: "lt_order_time",
    },
    /* {
      Header: "Type",
      accessor: "lt_order_type",
    }, */
    {
      Header: "Type",
      accessor: "lt_order_type",
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value == 0 ? 'BUY' : 'SELL' }</div>, // Adding a class to the cell
    },
    {
      Header: "Lots",
      accessor: "lt_order_lots",
    },
    {
      Header: "Symbol",
      accessor: "lt_order_symbol",
    },
    {
      Header: "Open Price ($)",
      accessor: "lt_order_price",
    },
    {
      Header: "SL",
      accessor: "lt_order_sl",
    },
    {
      Header: "TP",
      accessor: "lt_order_tp",
    },
    {
      Header: "Profit",
      accessor: "lt_order_profit",
    },
    {
      Header: "Comissionn",
      accessor: "lt_order_comm",
    },
    {
      Header: "Swap",
      accessor: "lt_order_swap",
    }
  ];